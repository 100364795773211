import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { FormError } from '../components/form-error';
import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client';
import logo from '../images/logo2.svg';
import { Button } from '../components/button';
import { FormErrorMessages } from '../enums/form-error-messages.enum';
import { Labels } from '../enums/labels.enum';
import { Titles } from '../enums/titles.enum';
import { EMAIL_REGEX } from '../constants';
import { Link, useHistory } from 'react-router-dom';
import { Exceptions } from '../enums/exceptions.enum';
import {
  recoverPasswordLinkMutation,
  recoverPasswordLinkMutationVariables,
} from '../__api__/recoverPasswordLinkMutation';
import { Modal as AModal } from 'antd';


const RECOVER_PASSWORD_LINK = gql`
  mutation recoverPasswordLinkMutation($input: EmailInput!) {
    recoverPasswordLink(input: $input)
  }
`;

interface IRecoverPasswordForm {
  email: string;
}

export const RecoverPassword = () => {
  const history = useHistory();

  const success = (content: string) => {
    AModal.success({
      content,
      onOk: () => {
        history.push('/');
      },
    });
  };

  const {
    register,
    getValues,
    errors,
    handleSubmit,
    formState,
  } = useForm<IRecoverPasswordForm>({
    mode: 'onChange',
  });
  const onCompleted = (data: recoverPasswordLinkMutation) => {
    const { recoverPasswordLink } = data;
    if (recoverPasswordLink) {
      success(
        `Se envió al correo ${getValues(
          'email',
        )}, las instrucciones para recuperar tu contraseña`,
      );
    }
  };
  const [recoverPasswordLinkMutation, { loading, error }] = useMutation<
    recoverPasswordLinkMutation,
    recoverPasswordLinkMutationVariables
  >(RECOVER_PASSWORD_LINK, {
    onCompleted,
  });
  const onSubmit = async () => {
    if (!loading) {
      try {
        const { email } = getValues();
        await recoverPasswordLinkMutation({
          variables: {
            input: {
              email,
            },
          },
        });
      } catch (error) {}
    }
  };

  return (
    <div className="flex flex-col justify-center px-2 py-12 sm:px-6 lg:px-8">
      <Helmet>
        <title>{Titles.RECOVER_PASSWORD}</title>
      </Helmet>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <Link to="/">
          <img className="w-auto h-40 mx-auto" src={logo} alt="Workflow" />
        </Link>
        <h2 className="mt-1 text-2xl font-extrabold text-center text-gray-900">
          {Labels.RECOVER_PASSWORD}
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label htmlFor="email" className="label">
                {Labels.EMAIL}
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_EMAIL,
                    pattern: {
                      value: EMAIL_REGEX,
                      message: FormErrorMessages.VALID_EMAIL,
                    },
                  })}
                  name="email"
                  type="email"
                  className="input"
                />
                {errors.email?.message && (
                  <FormError errorMessage={errors.email?.message} />
                )}
              </div>
            </div>

            <div>
              <Button
                canClick={formState.isValid}
                loading={loading}
                actionText={Labels.SEND}
              />
              {error && (
                <FormError errorMessage={Exceptions[error.message as any]} />
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
