import React, { useEffect, useState } from 'react'
import { parseFieldsToObject } from '../../../../utils/fields-utils';
import { useForm } from 'react-hook-form';
import { sha512 } from '../../../../utils/sha-512-crypto-utils';
import { useMe } from '../../../../hooks/use-me';
import { FormErrorMessages } from '../../../../enums/form-error-messages.enum';
import { FormLengthInputs } from '../../../../enums/form-length-inputs.enum';
import { FormError } from '../../../../components/form-error';
import { Button } from '../../../../components/button';
import { Labels } from '../../../../enums/labels.enum';
import { Exceptions } from '../../../../enums/exceptions.enum';
import { VALID_SIMPLE_NUMBER_REGEX } from '../../../../constants';
import { Modal as AModal } from 'antd';
import { ApolloError, gql, useMutation } from '@apollo/client';
import { ResponsePayValida } from '../../../../rest-api/enum-response/response.enum';
import { ResponsePayValidaCashout } from '../../../../__dto__/payvalida/responsePayvalidaCashout.interface';
import { roundWithTwoDecimals } from '../../../../utils/numeric-utils';
import { v4 as uuidv4 } from 'uuid';
import { ConsignmentStatus } from '../../../../__api__/globalTypes';
import { consultTellerIP } from '../../../../third-api/ip-teller.service';


const CONSULT_PAYMENT_PAYVALIDACASHOUT = gql`
  mutation consultCollectionPayValidaCashout($input: ConsultCollectionPayValidaCashoutInput!) {
    consultCollectionPayValidaCashout(input: $input) {
    code
    text
    data {
        amount,
        transactionCost,
        currency,
        description,
        document,
        otp,
        operation        
    },
    client_percent
    owner_percent
    }
  }
`;


const REGISTER_TRANSACTION_PAYVALIDACASHOUT = gql`
  mutation registerTransactionPayValidaCashout($input: RegisterTransactionPayValidaCashoutInput!) {
    registerTransactionPayValidaCashout(input: $input) {
    code
    text
    data 
    }
  }
`;

const CREATE_CONSIGNMENT_PAYVALIDA = gql`
  mutation CreateConsignmentPayvalida($input: CreateConsignmentInput!) {
    createConsignment(input: $input)
  }
`;


const CANCEL_TRANSACTION_PAYVALIDACASHOUT = gql`mutation cancelTransactionPayValidaCashout($input: CancelTransactionPayValidaCashoutInput!) {
    cancelTransactionPayValidaCashout(input: $input) {
      CODE
      DESC
      DATA {
        checksum
      }
    }
  }
  `;

const initialResponseConsultPayValidaCashout: ResponsePayValidaCashout = {
    code: '',
    text: '',
    data: {
        amount: 0,
        transactionCost: 0,
        currency: 'USD',
        description: '',
        document: '',
        otp: '',
        operation: '',
    },
    client_percent: 0,
    owner_percent: 0
};

const BuyProductMttrPayValidaCashout = ({ product, onOk, setLoadingPurcharse }) => {
    const { data, refetch } = useMe();
    const [teller, setTeller] = useState('');
    const [transaction, setTransaction] = useState('');
    const [consultCollectionPayValidaCashout, { loading: isLoadingConsultPayValidaCashout }] = useMutation(CONSULT_PAYMENT_PAYVALIDACASHOUT);
    const [registerTransactionPayValidaCashout, { loading: isLoadingRegisterTransactionPayValidaCashout, }] = useMutation(REGISTER_TRANSACTION_PAYVALIDACASHOUT);
    const [responsePayValidaCashout, setResponsePayValidaCashout] = useState(initialResponseConsultPayValidaCashout);
    const [cancelTransactionPayValidaCashout, { error: haveErrorCancelTransaction }] = useMutation(CANCEL_TRANSACTION_PAYVALIDACASHOUT);
    const [createConsignmentPayvalida, { loading: isLoadingConsigment, error: errorConsigment }] = useMutation(CREATE_CONSIGNMENT_PAYVALIDA);
    const {
        register,
        getValues,
        errors,
        handleSubmit,
        formState: { isValid },
    } = useForm({
        mode: 'onChange',
    });

    const success = (content: string) => {
        AModal.success({
            content
        });
    };

    const errorAlert = (content: string) => {
        AModal.error({
            content
        });
    };

    const onSearchPayment = async () => {
        const { ...input } = getValues();
        const contentForm = {
            fields: Object.keys(input).map((key: string) => ({
                name: key,
                value: input[key],
            })),
        };
        const parseContentForm = parseFieldsToObject(contentForm);
        const netName = process.env.REACT_APP_NET_NAME_CASHIN == undefined ? "" : process.env.REACT_APP_NET_NAME_CASHIN;
        const netCode = process.env.REACT_APP_NET_CODE_CASHIN == undefined ? "" : process.env.REACT_APP_NET_CODE_CASHIN;
        const fixedHash = process.env.REACT_APP_FIXED_HASH_CASHIN == undefined ? "" : process.env.REACT_APP_FIXED_HASH_CASHIN;
        const checksum = sha512(netName + parseContentForm.otp + parseContentForm.document + fixedHash)

        const payload = {
            otp: parseContentForm.otp,
            netcode: Number(netCode),
            document: parseContentForm.document,
            checksum,
            product_id: product.id
        }
        console.log(payload);
        const responseGql = await consultCollectionPayValidaCashout({ variables: { input: payload } });
        if (responseGql.data) {
            const respuestaCashout: ResponsePayValidaCashout = responseGql.data.consultCollectionPayValidaCashout;
            if (respuestaCashout.text === ResponsePayValida.OK) {
                setResponsePayValidaCashout(respuestaCashout);
            } else {
                setResponsePayValidaCashout(respuestaCashout);
                errorAlert(respuestaCashout.text);
            }
        }

    };

    const handlePayClick = async () => {
        setLoadingPurcharse(true);
        const netName = process.env.REACT_APP_NET_NAME_CASHIN == undefined ? "" : process.env.REACT_APP_NET_NAME_CASHIN;
        const netCode = process.env.REACT_APP_NET_CODE_CASHIN == undefined ? "" : process.env.REACT_APP_NET_CODE_CASHIN;
        const idoperation = responsePayValidaCashout.data?.operation;
        const fixedHash = process.env.REACT_APP_FIXED_HASH_CASHIN == undefined ? "" : process.env.REACT_APP_FIXED_HASH_CASHIN;
        const checksum = sha512(netName + idoperation + transaction + fixedHash);
        const geographicCode = process.env.REACT_APP_GEOGRAPHIC_CODE == undefined ? "" : process.env.REACT_APP_GEOGRAPHIC_CODE;
        const payload = {
            idoperation: idoperation,
            netcode: Number(netCode),
            transaction: transaction,
            teller: teller,
            danecode: geographicCode,
            checksum: checksum

        }
        const resultTransaction = await registerTransactionPayValidaCashout({ variables: { input: payload } });
        if (resultTransaction) {
            const respuestaCashout = resultTransaction.data.registerTransactionPayValidaCashout;
            if (respuestaCashout.text === ResponsePayValida.OK) {
                try {
                    const resultConsignament = await createConsignmentPayvalida({
                        variables: {
                            input: {
                                userId: data?.me.id,
                                amount: roundWithTwoDecimals(responsePayValidaCashout.data.amount + (responsePayValidaCashout.data.amount * responsePayValidaCashout.client_percent / 100)),
                                voucherNumber: `${responsePayValidaCashout.data.otp}${responsePayValidaCashout.data.document}`,
                                //attachedPath: '', // Campo de imagen vacio
                                transactionDate: new Date(),
                                bankAccountId: 'cm19npb552918qwwsorpbgjpp',
                                comment: `Monto: ${roundWithTwoDecimals(responsePayValidaCashout.data.amount)}, Comisión: ${roundWithTwoDecimals(responsePayValidaCashout.data.amount * responsePayValidaCashout.client_percent / 100)}, acreditado: ${roundWithTwoDecimals(responsePayValidaCashout.data.amount + (responsePayValidaCashout.data.amount * responsePayValidaCashout.client_percent / 100))}`,
                                status: ConsignmentStatus.ACCEPTED
                            },
                        },
                    });
                    if (errorConsigment) {
                        handleCancelTransaction();
                    } else {
                        if (resultConsignament.data) {
                            if (resultConsignament.data.createConsignment) {
                                refetch();
                                success(Labels.PAVVALID_SUCCESS_MESSAGE_LABEL);
                                setLoadingPurcharse(false);
                                handleEndTransaction();
                            }
                        } else {
                            handleCancelTransaction();
                        }
                    }
                } catch (err) {
                    const apolloError = err as ApolloError;
                    const graphQLErrors = apolloError.graphQLErrors;

                    if (graphQLErrors.length > 0) {
                        const message = graphQLErrors[0]?.message;

                        if (message === "VoucherNumberAlreadyExists") {
                            handleCancelTransaction();
                            // Mostrar mensaje en la UI
                        } else {
                            console.error("Error:", message);
                        }
                    }
                }
            } else {
                errorAlert(respuestaCashout.text);
                setLoadingPurcharse(false);
                handleEndTransaction();
            }
        }
        if (errorConsigment) {
            handleCancelTransaction();
        }
    }


    const handleCancelTransaction = async () => {

        const netName = process.env.REACT_APP_NET_NAME_CASHIN == undefined ? "" : process.env.REACT_APP_NET_NAME_CASHIN;
        const netCode = process.env.REACT_APP_NET_CODE_CASHIN == undefined ? "" : process.env.REACT_APP_NET_CODE_CASHIN;
        const fixedHash = process.env.REACT_APP_FIXED_HASH_CASHIN == undefined ? "" : process.env.REACT_APP_FIXED_HASH_CASHIN;
        const checksum = sha512(netName + transaction + fixedHash);
        const payload = {
            netcode: Number(netCode),
            transaction: transaction,
            checksum: checksum,
        }
        const resultCancelTransaction = await cancelTransactionPayValidaCashout({ variables: { input: payload } });
        if (resultCancelTransaction) {
            console.log(resultCancelTransaction.data)
            const respuestaCancelTransaction = resultCancelTransaction.data.cancelTransactionPayValidaCashout;
            if (respuestaCancelTransaction.DESC === ResponsePayValida.OK) {
                success(Labels.PAVVALID_CANCEL_MESSAGE_LABEL);
                setLoadingPurcharse(false);
                handleEndTransaction();
            } else {
                errorAlert(respuestaCancelTransaction.DESC);
                setLoadingPurcharse(false);
                handleEndTransaction();
            }
        }
        if (haveErrorCancelTransaction) {
            errorAlert(haveErrorCancelTransaction.message);
            setLoadingPurcharse(false);
            handleEndTransaction();
        }

    }

    const handleEndTransaction = () => {
        onOk();
    }


    useEffect(() => {

        const fetchIp = async () => {
            try {
                const ip = await consultTellerIP();
                setTeller(ip);
                setTransaction(uuidv4());
            } catch (error) {
                console.error('Error al obtener la IP:', error);
            }
        };

        fetchIp();
    }, []);

    return (
        <div className='flex flex-col justify-center px-2 py-0 sm:px-6 lg:px-8'>

            <form onSubmit={handleSubmit(onSearchPayment)} className="space-y-6">
                <div className="flex items-end space-x-4"> {/* Flex container para inputs y botón */}
                    <div className="w-1/3"> {/* Primer input */}
                        <label htmlFor="otp" className="label">
                            {Labels.PAYVALIDCASHOUT_OTP_NUMBER}
                        </label>
                        <div className="mt-1">
                            <input
                                ref={register({
                                    required: FormErrorMessages.REQUIRED_PAYVALIDCASHOUT_OTP_NUMBER,
                                    minLength: FormLengthInputs.MIN_LENGTH_PAYVALIDCASHOUT_OTP_NUMBER,
                                    maxLength: FormLengthInputs.MAX_LENGTH_PAYVALIDCASHOUT_OTP_NUMBER,
                                    validate: {
                                        validNumber: (value) => VALID_SIMPLE_NUMBER_REGEX.test(value),
                                    },
                                })}
                                name="otp"
                                minLength={FormLengthInputs.MIN_LENGTH_PAYVALIDCASHOUT_OTP_NUMBER}
                                maxLength={FormLengthInputs.MAX_LENGTH_PAYVALIDCASHOUT_OTP_NUMBER}
                                type="text"
                                className="input"
                            />
                            {errors.reference_number?.message && (
                                <FormError errorMessage={errors.reference_number?.message} />
                            )}
                            {(errors.reference_number?.type === 'minLength' ||
                                errors.reference_number?.type === 'maxLength') && (
                                    <FormError errorMessage={FormErrorMessages.LENGTH_PAYVALIDCASHOUT_REFERENCE_NUMBER} />
                                )}
                        </div>
                    </div>
                    <div className="w-1/3"> {/* Segundo input */}
                        <label htmlFor="document" className="label">
                            {Labels.PAYVALIDCASHOUT_DOCUMENT}
                        </label>
                        <div className="mt-1">
                            <input
                                ref={register({
                                    required: FormErrorMessages.REQUIRED_PAYVALIDCASHOUT_DOCUMENT,
                                    minLength: FormLengthInputs.MIN_LENGTH_PAYVALIDCASHOUT_DOCUMENT,
                                    maxLength: FormLengthInputs.MAX_LENGTH_PAYVALIDCASHOUT_DOCUMENT,
                                    validate: {
                                        validNumber: (value) => VALID_SIMPLE_NUMBER_REGEX.test(value),
                                    },
                                })}
                                name="document"
                                minLength={FormLengthInputs.MIN_LENGTH_PAYVALIDCASHOUT_DOCUMENT}
                                maxLength={FormLengthInputs.MAX_LENGTH_PAYVALIDCASHOUT_DOCUMENT}
                                type="text"
                                className="input"
                            />
                            {errors.reference_number?.message && (
                                <FormError errorMessage={errors.reference_number?.message} />
                            )}
                            {(errors.reference_number?.type === 'minLength' ||
                                errors.reference_number?.type === 'maxLength') && (
                                    <FormError errorMessage={FormErrorMessages.LENGTH_PAYVALIDCASHOUT_DOCUMENT} />
                                )}
                        </div>
                    </div>

                    <div className="w-1/6 flex items-center"> {/* Botón */}
                        <Button
                            canClick={isValid}
                            loading={isLoadingConsultPayValidaCashout}
                            actionText={Labels.PAVVALID_SEARCH_LABEL}
                        />
                    </div>
                </div>

                {errors && (
                    <FormError errorMessage={Exceptions[errors.message as any]} />
                )}

            </form>
            {
                (responsePayValidaCashout.data && responsePayValidaCashout.data.description !== "") && <div className="px-2 py-1 border-2 border-zinc-800">
                    {responsePayValidaCashout.data && <div className="flex items-center px-6 py-2">
                        <div className="w-2 h-2 bg-black rounded-full mr-3"></div>
                        <div>
                            {(responsePayValidaCashout.data && responsePayValidaCashout.data?.description) && <h2 className="text-lg font-bold text-gray-900">
                                {responsePayValidaCashout.data.description}
                            </h2>}
                        </div>
                    </div>}

                    {
                        responsePayValidaCashout.data.description !== "" &&
                        <div className="px-2 py-0 w-full" >
                            <div className="flex justify-between text-sm text-gray-600">
                                <div className="flex-1">
                                    <p className="font-medium">{Labels.PAYVALID_REFERENCE_NUMBER}</p>
                                    <p>{responsePayValidaCashout.data.document}</p>
                                </div>

                                <div className="flex-1 text-right">
                                    <div className="flex items-center space-x-2 justify-end">
                                        <span className="lex-shrink-0 inline-block px-2 text-base font-medium text-red-500 line-through bg-orange-200 rounded-full py-0.5">
                                            $ {roundWithTwoDecimals(responsePayValidaCashout.data.amount)}
                                        </span>

                                        <span className="flex-shrink-0 inline-block px-2 text-base font-medium text-blue-900 bg-blue-200 rounded-full py-0.5">
                                            $ {roundWithTwoDecimals(responsePayValidaCashout.data.amount + (responsePayValidaCashout.data.amount * responsePayValidaCashout.client_percent / 100))}
                                        </span>
                                    </div>

                                </div>
                            </div>
                            <div className="flex justify-end">
                                <div className="mt-2 border-2 border-red-500 rounded px-2 py-1 inline-block">
                                    <span className="font-bold text-gray-700">{Labels.PAVVALIDCASHOUT_DISPENSEAMOUNT}</span>
                                    <span className="text-gray-900 font-bold"> $ {roundWithTwoDecimals(responsePayValidaCashout.data.amount)}</span>
                                </div>
                            </div>
                            <div className="flex justify-end py-4">
                                {(!isLoadingRegisterTransactionPayValidaCashout && !isLoadingConsigment) && <button onClick={handlePayClick} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                                    {Labels.PAVVALID_PAY_LABEL}
                                </button>}
                                {(isLoadingRegisterTransactionPayValidaCashout || isLoadingConsigment) && <div className="flex items-center justify-center bg-gray-300 animate-pulse rounded px-2 py-2">
                                    <span className='font-semibold'>{Labels.PAVVALIDCASHOUT_LOADINGPAYMENT}</span>
                                    <div className="w-6 h-6 border-4 border-blue-500 border-t-transparent rounded-full animate-pulse"></div>
                                </div>

                                }
                            </div>
                        </div>
                    }

                </div>

            }
        </div>
    )
}

export default BuyProductMttrPayValidaCashout;