import { ReactChild, useEffect, useState } from 'react';
import {
  faCreditCard,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Labels } from '../../enums/labels.enum';
import { Titles } from '../../enums/titles.enum';
import { Helmet } from 'react-helmet-async';
import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';
import { Card, Collapse, Modal as AModal, Skeleton } from 'antd';
import { Modal } from '../../components/modal';
import { BuyProduct } from './buy-product';
import { BuyProduct2 } from './buy-product-part2';
import { BuyService } from './buy-service';
import { BuyService2 } from './buy-service-part2';
import { BuyProductMTTRDefault2 } from './mttr-dialogues/default/buy-product-mttr-default-2';
import { BuyProductMTTRDefault } from './mttr-dialogues/default/buy-product-mttr-default';
import { useMe } from '../../hooks/use-me';
import { roundWithTwoDecimals } from '../../utils/numeric-utils';
import { categoriesForCustomers } from '../../__api__/categoriesForCustomers';
import { families } from '../../__api__/families';
import {
  productsByCategoryId,
  productsByCategoryId_productsByCategoryId,
  productsByCategoryIdVariables,
} from '../../__api__/productsByCategoryId';
import {
  consultarServicioMutation_consultarServicio_package
} from '../../__api__/consultarServicioMutation';
import { productsSearcher, productsSearcherVariables } from '../../__api__/productsSearcher';
import { useParams } from 'react-router-dom';
import { IBuyProductMTTR2Props, IBuyProductMTTRProps } from './mttr-dialogues/buy-product-mttr-props.interface';
import { MTTRType } from '../../__api__/globalTypes';
import { BuyProductMTTRFreeFire2 } from './mttr-dialogues/freefire/buy-product-mttr-freefire-2';
import { BuyProductMTTRFreeFire } from './mttr-dialogues/freefire/buy-product-mttr-freefire';
import { BuyProductMTTRErosNow } from './mttr-dialogues/erosnow/buy-product-mttr-erosnow';
import { BuyProductMTTRGenshin } from './mttr-dialogues/genshinimpact/buy-product-mttr-genshin';
import { BuyProductMTTRMobileLegends } from './mttr-dialogues/mobilelegends/buy-product-mttr-mobilelegends';
import { BuyProductMTTRNetDragon } from './mttr-dialogues/netdragon/buy-product-mttr-netdragon';
import { BuyProductMTTRNimoTV } from './mttr-dialogues/nimotv/buy-product-mttr-nimotv';
import { BuyProductMTTRRazerGold } from './mttr-dialogues/razergold/buy-product-mttr-razergold';
import { BuyProductMTTRErosNow2 } from './mttr-dialogues/erosnow/buy-product-mttr-erosnow-2';
import { BuyProductMTTRGenshin2 } from './mttr-dialogues/genshinimpact/buy-product-mttr-genshin-2';
import { BuyProductMTTRMobileLegends2 } from './mttr-dialogues/mobilelegends/buy-product-mttr-mobilelegends-2';
import { BuyProductMTTRNetDragon2 } from './mttr-dialogues/netdragon/buy-product-mttr-netdragon-2';
import { BuyProductMTTRNimoTV2 } from './mttr-dialogues/nimotv/buy-product-mttr-nimotv-2';
import { BuyProductMTTRRazerGold2 } from './mttr-dialogues/razergold/buy-product-mttr-razergold-2';
import { BuyProductMTTRGiftcard } from './mttr-dialogues/giftcards/buy-product-mttr-giftcards';
import { BuyProductMTTRESET } from './mttr-dialogues/eset/buy-product-mttr-eset';
import BuyProductMttrPayvalida from './mttr-dialogues/payvalida/buy-product-mttr-payvalida';
import { ModalPayValida } from '../../components/payvalida/modal-payvalida';
import BuyProductMttrPayValidaCashout from './mttr-dialogues/payvalida/buy-product-mttr-payvalida-cashout';

const { Panel } = Collapse;

const FAMILIES_FOR_CUSTOMERS = gql`
  query families {
    families {
      id
      name
      index
      imagePath
    }
  }
`;

const CATEGORIES_FOR_CUSTOMERS = gql`
  query categoriesForCustomers($input: CategorySearcherInput!) {
    categoriesForCustomers(input: $input) {
      id
      name
      imageURL
    }
  }
`;

const PRODUCTS_BY_CATEGORY_ID = gql`
  query productsByCategoryId($input: ProductByCategoryIdInput!) {
    productsByCategoryId(input: $input) {
      id
      subid
      name
      description
      price
      pvp
      requiredInfo
      isAcceptDiscounts
      isImmediateResponse
      discount
      priceWithDiscount
      mttrReqInfo
      mttrID
      mttrType
    }
  }
`;

const PRODUCTS_SEARCHER = gql`
  query productsSearcher($input: ProductBySearcherInput!) {
    productsSearcher(input: $input) {
      id
      subid
      name
      description
      price
      pvp
      requiredInfo
      isAcceptDiscounts
      isImmediateResponse
      discount
      priceWithDiscount
      mttrReqInfo
      mttrID
      mttrType
    }
  }
`;

const success = () => {
  AModal.success({
    content: 'Compra exitosa. Revisa el estado en el menú de Compras.',
  });
};

export const Products = () => {
  const { search: searchParam } = useParams<{ search?: string }>();
  const [isInit, setisInit] = useState(false);
  const { data: user } = useMe();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleModalPayValida, setIsModalVisiblePayValida] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [isModalServiceVisible, setIsModalServiceVisible] = useState(false);
  const [isModalServiceVisible2, setIsModalServiceVisible2] = useState(false);
  const [isModalMTTRVisible, setIsModalMTTRVisible] = useState(false);
  const [isModalMTTRVisible2, setIsModalMTTRVisible2] = useState(false);
  const [categoryId, setCategoryId] = useState<string | null>(null);
  const [search, setSearch] = useState<string>("");
  const [searchTemp, setSearchTemp] = useState<string>("");
  const [familyId, setFamilyId] = useState<number | null>(searchParam ? -1 : 1);
  const [familyName, setFamilyName] = useState<string | null>('');
  const [product, setProduct] = useState<productsByCategoryId_productsByCategoryId | null>(null);
  const [consulta, setConsulta] = useState<consultarServicioMutation_consultarServicio_package | null>(null);
  const [consultaMTTR, setConsultaMTTR] = useState<any | null>(null);
  const [details, setDetails] = useState<any | null>(null);
  const [activeTab, setActiveTab] = useState(0);
  const [agrupedRecargas, setAgrupedRecargas] = useState<productsByCategoryId_productsByCategoryId[]>([]);
  const [agrupedPaquetes, setAgrupedPaquetes] = useState<productsByCategoryId_productsByCategoryId[]>([]);
  const [loadingPurcharse, setLoadingPurcharse] = useState<boolean>(false);

  const { data: familyData } = useQuery<families>(
    FAMILIES_FOR_CUSTOMERS,
    {
      fetchPolicy: 'network-only',
    },
  );

  const { data, loading } = useQuery<categoriesForCustomers>(
    CATEGORIES_FOR_CUSTOMERS,
    {
      fetchPolicy: 'network-only',
      variables: {
        input: {
          where: familyId == undefined ? 1 : familyId,
        },
      },
    },
  );

  const { data: productsData, loading: loadingProducts } = useQuery<
    productsByCategoryId,
    productsByCategoryIdVariables
  >(PRODUCTS_BY_CATEGORY_ID, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        categoryId: categoryId || '',
        customerId: user?.me.customer?.id || '',
      },
    },
  });

  const { data: productsSearcherData, loading: loadingSearcherProducts } = useQuery<
    productsSearcher,
    productsSearcherVariables
  >(PRODUCTS_SEARCHER, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        search: search || '',
        customerId: user?.me.customer?.id || '',
      },
    },
  });

  const changeFamily = (val: number) => {
    setFamilyId(val);
    const familyFound = familyData?.families?.find((family) => family.id === val);
    setFamilyName(familyFound?.name ?? '');

  };

  const changeProducts = (key: string | string[]) => {
    setCategoryId(key as string);
  };

  useEffect(() => {
    if (data?.categoriesForCustomers) {
      if (familyName === "Recargas Celulares" || familyName === "Recargas Internacionales") {
        setAgrupedRecargas(productsData?.productsByCategoryId.filter((product) => product.name.startsWith('Recarga')) ?? []);
        // setAgrupedPaquetes(productsData?.productsByCategoryId.filter((product) => product.name.includes('PAQ')) ?? []);
        setAgrupedPaquetes(
          productsData?.productsByCategoryId
            .filter((product) => product.name.includes('PAQ') || product.name.includes('MEGA') || product.name.includes('GOL')) ?? []
        );
      }
    }
  }, [data?.categoriesForCustomers, productsData?.productsByCategoryId]);

  const changeSearch = () => {
    setSearch(searchTemp);
  }

  function getMTTRDialog(data: IBuyProductMTTRProps): ReactChild {
    switch (data.product?.mttrType) {
      case MTTRType.FREEFIRE:
        return <BuyProductMTTRFreeFire
          onOk={data.onOk}
          product={data.product}
        />
      case MTTRType.EROSNOW:
        return <BuyProductMTTRErosNow
          onOk={data.onOk}
          product={data.product}
        />
      case MTTRType.GENSHINIMPACT:
        return <BuyProductMTTRGenshin
          onOk={data.onOk}
          product={data.product}
        />
      case MTTRType.MOBILELEGENDS:
        return <BuyProductMTTRMobileLegends
          onOk={data.onOk}
          product={data.product}
        />
      case MTTRType.NETDRAGON:
        return <BuyProductMTTRNetDragon
          onOk={data.onOk}
          product={data.product}
        />
      case MTTRType.NIMOTV:
        return <BuyProductMTTRNimoTV
          onOk={data.onOk}
          product={data.product}
        />
      case MTTRType.RAZERGOLD:
        return <BuyProductMTTRRazerGold
          onOk={data.onOk}
          product={data.product}
        />
      case MTTRType.GIFTCARD:
        return <BuyProductMTTRGiftcard
          onOk={data.onOk}
          product={data.product}
        />
      case MTTRType.ESET:
        return <BuyProductMTTRESET
          onOk={data.onOk}
          product={data.product}
          setLoadingPurcharse={setLoadingPurcharse}
        />
      case MTTRType.PAYVALIDA:
        return <BuyProductMttrPayvalida
          onOk={data.onOk}
          product={data.product}
          setLoadingPurcharse={setLoadingPurcharse}
        />
      case MTTRType.PAYVALIDACASHOUT:
        return <BuyProductMttrPayValidaCashout
          onOk={data.onOk}
          product={data.product}
          setLoadingPurcharse={setLoadingPurcharse}
        />
      default:
        return <BuyProductMTTRDefault
          onOk={data.onOk}
          product={data.product}
        />
    }

  }

  function getMTTR2Dialog(data: IBuyProductMTTR2Props): ReactChild {
    switch (data.product?.mttrType) {
      case MTTRType.FREEFIRE:
        return <BuyProductMTTRFreeFire2
          onOk={data.onOk}
          product={data.product}
          details={data.details}
          setLoadingPurcharse={setLoadingPurcharse}
        />
      case MTTRType.EROSNOW:
        return <BuyProductMTTRErosNow2
          onOk={data.onOk}
          product={data.product}
          details={data.details}
          setLoadingPurcharse={setLoadingPurcharse}
        />
      case MTTRType.GENSHINIMPACT:
        return <BuyProductMTTRGenshin2
          onOk={data.onOk}
          product={data.product}
          details={data.details}
          setLoadingPurcharse={setLoadingPurcharse}
        />
      case MTTRType.MOBILELEGENDS:
        return <BuyProductMTTRMobileLegends2
          onOk={data.onOk}
          product={data.product}
          details={data.details}
          setLoadingPurcharse={setLoadingPurcharse}
        />
      case MTTRType.NETDRAGON:
        return <BuyProductMTTRNetDragon2
          onOk={data.onOk}
          product={data.product}
          details={data.details}
          setLoadingPurcharse={setLoadingPurcharse}
        />
      case MTTRType.NIMOTV:
        return <BuyProductMTTRNimoTV2
          onOk={data.onOk}
          product={data.product}
          details={data.details}
          setLoadingPurcharse={setLoadingPurcharse}
        />
      case MTTRType.RAZERGOLD:
        return <BuyProductMTTRRazerGold2
          onOk={data.onOk}
          product={data.product}
          details={data.details}
          setLoadingPurcharse={setLoadingPurcharse}
        />
      case MTTRType.GIFTCARD:
        return <BuyProductMTTRGiftcard
          onOk={data.onOk}
          product={data.product}
          setLoadingPurcharse={setLoadingPurcharse}
        />
      case MTTRType.ESET:
        return <BuyProductMTTRESET
          onOk={data.onOk}
          product={data.product}
          setLoadingPurcharse={setLoadingPurcharse}
        />
      default:
        return <BuyProductMTTRDefault2
          onOk={data.onOk}
          product={data.product}
          details={data.details}
          setLoadingPurcharse={setLoadingPurcharse}
        />
    }

  }

  const tabs = () => {
    if (familyData == undefined) {
      return [];
    }
    const htmls: any[] = [];
    for (const fam of familyData.families) {
      if (fam.id == familyId) {
        htmls.push(
          <div className="h-full w-32 flex flex-col mx-2 rounded bg-gray-500 py-2 px-2">
            <img src={fam.imagePath ?? ''}
              alt="" className='h-4/5' />
            <span className='h-1/5 text-sm truncate'>{fam.name}</span>
          </div>
        );

      }
      else {
        htmls.push(<div className="h-full w-32 flex flex-col mx-2 rounded bg-white py-2 px-2 cursor-pointer border-2 border-solid border-gray-500" onClick={() => changeFamily(fam.id)}>
          <img src={fam.imagePath ?? ''}
            alt="" className='h-4/5' />
          <span className='h-1/5 text-sm truncate'>{fam.name}</span>
        </div>);
      }
    }
    if (-1 == familyId) {
      htmls.push(<div className="h-full w-32 flex flex-col mx-2 rounded bg-gray-500 py-2 px-2 cursor-pointer " onClick={() => changeFamily(-1)}>
        <img src={'https://w7.pngwing.com/pngs/248/925/png-transparent-computer-icons-search-for-miscellaneous-computer-program-android-thumbnail.png'}
          alt="" className='h-4/5' />
        <span className='h-1/5 text-sm truncate'>Buscar</span>
      </div>);

    }
    else {
      htmls.push(
        <div className="h-full w-32 flex flex-col mx-2 rounded bg-white py-2 px-2 cursor-pointer  border-2 border-solid border-gray-500" onClick={() => changeFamily(-1)}>
          <img src={'https://w7.pngwing.com/pngs/248/925/png-transparent-computer-icons-search-for-miscellaneous-computer-program-android-thumbnail.png'}
            alt="" className='h-4/5' />
          <span className='h-1/5 text-sm truncate'>Buscar</span>
        </div>
      );
    }
    return htmls;
  };

  useEffect(() => {
    if (searchParam && !loadingProducts && !loadingSearcherProducts && !isInit) {
      setisInit(true);
      setSearchTemp(searchParam);
      setSearch(searchParam);
    }
  }, [search, loadingProducts, loadingSearcherProducts]);

  return (
    <div className="py-6">
      <Helmet>
        <title>{Titles.PRODUCTS}</title>
      </Helmet>
      <Modal
        title={Labels.BUY_PRODUCT}
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => !loadingPurcharse && setIsModalVisible(false)}
        closable={!loadingPurcharse}
        child={
          <BuyProduct
            onOk={(value: any) => {
              setDetails(value);
              setIsModalVisible(false);
              setIsModalVisible2(true);
            }}
            product={product}
          />
        }
      />
      <Modal
        title={Labels.BUY_PRODUCT}
        visible={isModalVisible2}
        onOk={() => setIsModalVisible2(false)}
        onCancel={() => !loadingPurcharse && setIsModalVisible2(false)}
        closable={!loadingPurcharse}
        child={
          <BuyProduct2
            onOk={() => {
              setIsModalVisible2(false);
              success();
            }}
            setLoadingPurcharse={setLoadingPurcharse}
            product={product}
            details={details}
          />
        }
      />
      <Modal
        title={Labels.BUY_SERVICE}
        visible={isModalServiceVisible}
        onOk={() => { setIsModalServiceVisible(false); }}
        onCancel={() => !loadingPurcharse && setIsModalServiceVisible(false)}
        closable={!loadingPurcharse}
        child={
          <BuyService
            onOk={(value: consultarServicioMutation_consultarServicio_package) => {
              setConsulta(value);
              setIsModalServiceVisible(false);
              setIsModalServiceVisible2(true);
            }}
            product={product}
          />
        }
      />
      <Modal
        title={Labels.BUY_SERVICE}
        visible={isModalServiceVisible2}

        onOk={() => { setIsModalServiceVisible2(false); }}
        onCancel={() => !loadingPurcharse && setIsModalServiceVisible2(false)}
        closable={!loadingPurcharse}
        child={
          <BuyService2
            onOk={() => {
              setIsModalServiceVisible2(false);
              success();
            }}
            product={product}
            consulta={consulta}
            setLoadingPurcharse={setLoadingPurcharse}
          />
        }
      />
      <Modal
        title={Labels.BUY_PRODUCT}
        visible={isModalMTTRVisible}
        onOk={() => { setIsModalMTTRVisible(false); }}
        onCancel={() => !loadingPurcharse && setIsModalMTTRVisible(false)}
        closable={!loadingPurcharse}
        child={getMTTRDialog({
          product: product, onOk: (value: consultarServicioMutation_consultarServicio_package) => {
            if (product?.mttrType != MTTRType.GIFTCARD && product?.mttrType != MTTRType.ESET) {
              setConsultaMTTR(value);
              setIsModalMTTRVisible2(true);
            }
            else {
              success();
            }
            setIsModalMTTRVisible(false);
          }
        })}
      />
      <Modal
        title={Labels.BUY_PRODUCT}
        visible={isModalMTTRVisible2}
        onOk={() => { setIsModalMTTRVisible2(false); }}
        onCancel={() => !loadingPurcharse && setIsModalMTTRVisible2(false)}
        closable={!loadingPurcharse}
        child={getMTTR2Dialog({
          product: product, details: consultaMTTR, onOk: () => {
            setIsModalMTTRVisible2(false);
            success();
          }
        })}
      />

      <ModalPayValida
        title={Labels.BUY_PAYVALID}
        visible={isModalVisibleModalPayValida}
        onOk={() => {
          setIsModalVisiblePayValida(false);
        }}
        onCancel={() => !loadingPurcharse && setIsModalVisiblePayValida(false)}
        child={getMTTRDialog({
          product: product, onOk: () => {
            setIsModalVisiblePayValida(false);
          }
        })}
      />
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <h3 className="px-3 mb-4 text-lg font-medium text-gray-900 leading-6 sm:px-0">
          <div className='bg-white w-full text-center rounded mb-2 py-1'> {Labels.MENU_PRODUCT_CATEGORY}</div>
          <div className="overflow-x-auto">
            <div className="flex w-max h-32 items-center">
              {tabs()}
            </div>
          </div>
          <div></div>
        </h3>
        {
          -1 == familyId ?
            (
              <div>
                <div className="mt-5 mb-5 md:mt-0 md:col-span-2">
                  <form className="flex flex-col items-center w-full py-8 bg-white shadow sm:rounded-3xl sm:px-4" onSubmit={(event) => {
                    event.preventDefault();
                    changeSearch();
                  }}>
                    <div className="flex items-center justify-center">
                      <label
                        htmlFor="productName"
                        className="block text-sm font-medium text-gray-700"
                      >
                        <span className="mr-3">Producto</span>
                      </label>
                      <input
                        onChange={(event) => {
                          const value = event.target.value;
                          setSearchTemp(value);
                        }}
                        name="productName"
                        type="text"
                        className="input"
                      />
                    </div>
                  </form>
                </div>
                {loadingSearcherProducts && <Skeleton active />}
                {!loadingSearcherProducts &&
                  productsSearcherData?.productsSearcher &&
                  productsSearcherData.productsSearcher.length > 0 ? (
                  <div>
                    <ul className="px-3 sm:px-0 grid grid-cols-1 gap-6 md:grid-cols-2">
                      {productsSearcherData?.productsSearcher?.map((product) => {
                        const finalPrice: number =
                          product.discount > 0
                            ? product.priceWithDiscount
                            : product.price;
                        return (
                          <li key={product.id}>
                            <Card
                              actions={[
                                <div
                                  className={`flex -mt-px divide-x divide-gray-200 ${(user?.me.customer?.balance || 0) < finalPrice
                                    ? 'text-gray-400 pointer-events-none'
                                    : 'text-gray-700 hover:text-gray-500 cursor-pointer'
                                    }`}
                                  onClick={() => {
                                    setProduct(product);
                                    if (product.mttrID != undefined && product.mttrID != "") {
                                      setIsModalMTTRVisible(true);
                                    }
                                    else if (product.requiredInfo == "" || product.requiredInfo == undefined) {
                                      setIsModalServiceVisible(true);
                                    }
                                    else {
                                      setIsModalVisible(true);
                                    }
                                  }}
                                >
                                  <div className="flex flex-1 w-0">
                                    <div className="relative inline-flex items-center justify-center flex-1 w-0 -mr-px text-sm font-medium border border-transparent rounded-bl-lg">
                                      <FontAwesomeIcon
                                        aria-hidden="true"
                                        icon={faCreditCard}
                                      />
                                      <span className="ml-3">{Labels.BUY}</span>
                                    </div>
                                  </div>
                                </div>,
                              ]}
                            >
                              <div className="flex items-center justify-between w-full space-x-6">
                                <div className="flex-1 truncate">
                                  <div className="flex items-center space-x-3">
                                    <h3 className="text-sm font-medium text-gray-900 truncate">
                                      {product.name}
                                    </h3>
                                    {product.discount > 0 && (
                                      <span className="flex-shrink-0 inline-block px-2 text-base font-medium text-red-500 line-through bg-orange-200 rounded-full py-0.5">
                                        $ {roundWithTwoDecimals(product.price)}
                                      </span>
                                    )}
                                    {product.priceWithDiscount > 0 && (<span className="flex-shrink-0 inline-block px-2 text-base font-medium text-blue-900 bg-blue-200 rounded-full py-0.5">
                                      ${' '}
                                      {roundWithTwoDecimals(
                                        product.priceWithDiscount,
                                      )}
                                    </span>)}
                                  </div>
                                  <div className="mt-1 text-sm text-gray-500 truncate">
                                    {product.description}
                                    <br />
                                    {((product?.pvp || 0) > 0) && (<div style={{ border: "2px solid red", width: "fit-content", borderRadius: "10px", padding: "2px" }}>Precio de Venta: <span style={{ fontWeight: "bold", fontSize: "1rem" }}>${roundWithTwoDecimals(product?.pvp || 0)}</span></div>)}

                                  </div>
                                  <div className="mt-1 text-sm text-blue-500 truncate">
                                    {product.isImmediateResponse ? "Entrega Inmediata" : "Entrega no Inmediata"}
                                  </div>
                                  {(user?.me.customer?.balance || 0) <
                                    finalPrice && (
                                      <div className="mt-2 text-orange-400">
                                        <FontAwesomeIcon
                                          aria-hidden="true"
                                          icon={faExclamationTriangle}
                                        />
                                        <span className="ml-3 text-xs">
                                          No tienes saldo suficiente para comprar
                                          este producto
                                        </span>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </Card>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ) : (
                  !loadingSearcherProducts && (
                    <div>
                      <span>No hay productos disponibles en este momento</span>
                    </div>
                  )
                )}
              </div>
            ) :
            (
              <div>
                {loading && <Skeleton active />}
                {!loading &&
                  data?.categoriesForCustomers &&
                  data.categoriesForCustomers.length > 0 ? (
                  <>
                    {console.log("Family", familyName)}
                    {(familyName !== "Recargas Celulares" && familyName !== "Recargas Internacionales") ?
                      (<Collapse accordion onChange={changeProducts}>
                        {data?.categoriesForCustomers.map((category) => (
                          <Panel
                            header={
                              <div className="flex flex-wrap items-center justify-between -mt-4 -ml-4 sm:flex-nowrap">
                                <div className="mt-4 ml-4">
                                  <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                      <img
                                        className="inline-block px-1 py-1 border-2 border-indigo-900 w-14 h-14"
                                        src={category.imageURL}
                                        alt={category.name}
                                      />
                                    </div>
                                    <div className="ml-4">
                                      <h3 className="text-base font-bold text-gray-900 leading-6">
                                        {category.name}
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                            key={category.id}
                          >
                            {loadingProducts && <Skeleton active />}
                            {!loadingProducts && (
                              <ul className="px-3 sm:px-0 grid grid-cols-1 gap-6 md:grid-cols-2">
                                {productsData?.productsByCategoryId?.map((product) => {
                                  const finalPrice: number =
                                    product.discount > 0
                                      ? product.priceWithDiscount
                                      : product.price;
                                  return (
                                    <li key={product.id}>
                                      <Card
                                        actions={[
                                          <div
                                            className={`flex -mt-px divide-x divide-gray-200 ${(user?.me.customer?.balance || 0) < finalPrice
                                              ? 'text-gray-400 pointer-events-none'
                                              : 'text-gray-700 hover:text-gray-500 cursor-pointer'
                                              }`}
                                            onClick={() => {
                                              setProduct(product);
                                              if (product.mttrID != undefined && product.mttrID != "") {
                                                setIsModalMTTRVisible(true);
                                              }
                                              else if (product.requiredInfo == "" || product.requiredInfo == undefined) {
                                                setIsModalServiceVisible(true);

                                              } else if (product.mttrType === MTTRType.PAYVALIDA || product.mttrType === MTTRType.PAYVALIDACASHOUT) {
                                                console.log("ok");
                                                setIsModalVisiblePayValida(true);
                                              }
                                              else {
                                                setIsModalVisible(true);
                                              }
                                            }}
                                          >
                                            <div className="flex flex-1 w-0">
                                              <div className="relative inline-flex items-center justify-center flex-1 w-0 -mr-px text-sm font-medium border border-transparent rounded-bl-lg">
                                                <FontAwesomeIcon
                                                  aria-hidden="true"
                                                  icon={faCreditCard}
                                                />
                                                <span className="ml-3">{Labels.BUY}</span>
                                              </div>
                                            </div>
                                          </div>,
                                        ]}
                                      >
                                        <div className="flex items-center justify-between w-full space-x-6">
                                          <div className="flex-1 truncate">
                                            <div className="flex items-center space-x-3">
                                              <h3 className="text-sm font-medium text-gray-900 truncate">
                                                {product.name}
                                              </h3>
                                              {product.discount > 0 && (
                                                <span className="flex-shrink-0 inline-block px-2 text-base font-medium text-red-500 line-through bg-orange-200 rounded-full py-0.5">
                                                  $ {roundWithTwoDecimals(product.price)}
                                                </span>
                                              )}
                                              {product.priceWithDiscount > 0 && (<span className="flex-shrink-0 inline-block px-2 text-base font-medium text-blue-900 bg-blue-200 rounded-full py-0.5">
                                                ${' '}
                                                {roundWithTwoDecimals(
                                                  product.priceWithDiscount,
                                                )}
                                              </span>)}
                                            </div>
                                            <div className="mt-1 text-sm text-gray-500 truncate">
                                              {product.description}
                                              <br />
                                              {((product?.pvp || 0) > 0) && (<div style={{ border: "2px solid red", width: "fit-content", borderRadius: "10px", padding: "2px" }}>Precio de Venta: <span style={{ fontWeight: "bold", fontSize: "1rem" }}>${roundWithTwoDecimals(product?.pvp || 0)}</span></div>)}
                                            </div>

                                            <div className="mt-1 text-sm text-blue-500 truncate">
                                              {product.isImmediateResponse ? "Entrega Inmediata" : "Entrega no Inmediata"}
                                            </div>
                                            {(user?.me.customer?.balance || 0) <
                                              finalPrice && (
                                                <div className="mt-2 text-orange-400">
                                                  <FontAwesomeIcon
                                                    aria-hidden="true"
                                                    icon={faExclamationTriangle}
                                                  />
                                                  <span className="ml-3 text-xs">
                                                    No tienes saldo suficiente para comprar
                                                    este producto
                                                  </span>
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                      </Card>
                                    </li>
                                  );
                                })}
                              </ul>
                            )}
                          </Panel>
                        ))}
                      </Collapse>) : (


                        <Collapse accordion onChange={changeProducts}>
                          {data?.categoriesForCustomers.map((category) => (
                            <Panel
                              header={
                                <div className="flex flex-wrap items-center justify-between -mt-4 -ml-4 sm:flex-nowrap">
                                  <div className="mt-4 ml-4">
                                    <div className="flex items-center">
                                      <div className="flex-shrink-0">
                                        <img
                                          className="inline-block px-1 py-1 border-2 border-indigo-900 w-14 h-14"
                                          src={category.imageURL}
                                          alt={category.name}
                                        />
                                      </div>
                                      <div className="ml-4">
                                        <h3 className="text-base font-bold text-gray-900 leading-6">
                                          {category.name}
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }
                              key={category.id}
                            >
                              {loadingProducts && <Skeleton active />}
                              {!loadingProducts && (
                                <div className="w-full">
                                  <div className="flex justify-center space-x-4 border-b-2 border-gray-200">
                                    <button
                                      onClick={() => setActiveTab(0)}
                                      className={`py-2 px-4 font-semibold ${activeTab === 0 ? 'text-blue-500 border-b-4 border-blue-500' : 'text-gray-500'
                                        }`}
                                    >
                                      Recargas
                                    </button>
                                    <button
                                      onClick={() => setActiveTab(1)}
                                      className={`py-2 px-4 font-semibold ${activeTab === 1 ? 'text-blue-500 border-b-4 border-blue-500' : 'text-gray-500'
                                        }`}
                                    >
                                      Paquetes
                                    </button>
                                  </div>


                                  <div className="mt-4 p-4">
                                    {activeTab === 0 && (
                                      <ul className="px-3 sm:px-0 grid grid-cols-1 gap-6 md:grid-cols-2">
                                        {agrupedRecargas.map((product) => {
                                          const finalPrice: number =
                                            product.discount > 0
                                              ? product.priceWithDiscount
                                              : product.price;
                                          return (
                                            <li key={product.id}>
                                              <Card
                                                actions={[
                                                  <div
                                                    className={`flex -mt-px divide-x divide-gray-200 ${(user?.me.customer?.balance || 0) < finalPrice
                                                      ? 'text-gray-400 pointer-events-none'
                                                      : 'text-gray-700 hover:text-gray-500 cursor-pointer'
                                                      }`}
                                                    onClick={() => {
                                                      setProduct(product);
                                                      if (product.mttrID != undefined && product.mttrID != "") {
                                                        setIsModalMTTRVisible(true);
                                                      }
                                                      else if (product.requiredInfo == "" || product.requiredInfo == undefined) {
                                                        setIsModalServiceVisible(true);

                                                      } else if (product.mttrType === MTTRType.PAYVALIDA || product.mttrType === MTTRType.PAYVALIDACASHOUT) {
                                                        console.log("ok");
                                                        setIsModalVisiblePayValida(true);
                                                      }
                                                      else {
                                                        setIsModalVisible(true);
                                                      }
                                                    }}
                                                  >
                                                    <div className="flex flex-1 w-0">
                                                      <div className="relative inline-flex items-center justify-center flex-1 w-0 -mr-px text-sm font-medium border border-transparent rounded-bl-lg">
                                                        <FontAwesomeIcon
                                                          aria-hidden="true"
                                                          icon={faCreditCard}
                                                        />
                                                        <span className="ml-3">{Labels.BUY}</span>
                                                      </div>
                                                    </div>
                                                  </div>,
                                                ]}
                                              >
                                                <div className="flex items-center justify-between w-full space-x-6">
                                                  <div className="flex-1 truncate">
                                                    <div className="flex items-center space-x-3">
                                                      <h3 className="text-sm font-medium text-gray-900 truncate">
                                                        {product.name}
                                                      </h3>
                                                      {product.discount > 0 && (
                                                        <span className="flex-shrink-0 inline-block px-2 text-base font-medium text-red-500 line-through bg-orange-200 rounded-full py-0.5">
                                                          $ {roundWithTwoDecimals(product.price)}
                                                        </span>
                                                      )}
                                                      {product.priceWithDiscount > 0 && (<span className="flex-shrink-0 inline-block px-2 text-base font-medium text-blue-900 bg-blue-200 rounded-full py-0.5">
                                                        ${' '}
                                                        {roundWithTwoDecimals(
                                                          product.priceWithDiscount,
                                                        )}
                                                      </span>)}
                                                    </div>
                                                    <div className="mt-1 text-sm text-gray-500 truncate">
                                                      {product.description}
                                                      <br />
                                                      {((product?.pvp || 0) > 0) && (<div style={{ border: "2px solid red", width: "fit-content", borderRadius: "10px", padding: "2px" }}>Precio de Venta: <span style={{ fontWeight: "bold", fontSize: "1rem" }}>${roundWithTwoDecimals(product?.pvp || 0)}</span></div>)}
                                                    </div>

                                                    <div className="mt-1 text-sm text-blue-500 truncate">
                                                      {product.isImmediateResponse ? "Entrega Inmediata" : "Entrega no Inmediata"}
                                                    </div>
                                                    {(user?.me.customer?.balance || 0) <
                                                      finalPrice && (
                                                        <div className="mt-2 text-orange-400">
                                                          <FontAwesomeIcon
                                                            aria-hidden="true"
                                                            icon={faExclamationTriangle}
                                                          />
                                                          <span className="ml-3 text-xs">
                                                            No tienes saldo suficiente para comprar
                                                            este producto
                                                          </span>
                                                        </div>
                                                      )}
                                                  </div>
                                                </div>
                                              </Card>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    )}
                                    {activeTab === 1 && (
                                      <ul className="px-3 sm:px-0 grid grid-cols-1 gap-6 md:grid-cols-2">
                                        {agrupedPaquetes.map((product) => {
                                          const finalPrice: number =
                                            product.discount > 0
                                              ? product.priceWithDiscount
                                              : product.price;
                                          return (
                                            <li key={product.id}>
                                              <Card
                                                actions={[
                                                  <div
                                                    className={`flex -mt-px divide-x divide-gray-200 ${(user?.me.customer?.balance || 0) < finalPrice
                                                      ? 'text-gray-400 pointer-events-none'
                                                      : 'text-gray-700 hover:text-gray-500 cursor-pointer'
                                                      }`}
                                                    onClick={() => {
                                                      setProduct(product);
                                                      if (product.mttrID != undefined && product.mttrID != "") {
                                                        setIsModalMTTRVisible(true);
                                                      }
                                                      else if (product.requiredInfo == "" || product.requiredInfo == undefined) {
                                                        setIsModalServiceVisible(true);

                                                      } else if (product.mttrType === MTTRType.PAYVALIDA || product.mttrType === MTTRType.PAYVALIDACASHOUT) {
                                                        console.log("ok");
                                                        setIsModalVisiblePayValida(true);
                                                      }
                                                      else {
                                                        setIsModalVisible(true);
                                                      }
                                                    }}
                                                  >
                                                    <div className="flex flex-1 w-0">
                                                      <div className="relative inline-flex items-center justify-center flex-1 w-0 -mr-px text-sm font-medium border border-transparent rounded-bl-lg">
                                                        <FontAwesomeIcon
                                                          aria-hidden="true"
                                                          icon={faCreditCard}
                                                        />
                                                        <span className="ml-3">{Labels.BUY}</span>
                                                      </div>
                                                    </div>
                                                  </div>,
                                                ]}
                                              >
                                                <div className="flex items-center justify-between w-full space-x-6">
                                                  <div className="flex-1 truncate">
                                                    <div className="flex items-center space-x-3">
                                                      <h3 className="text-sm font-medium text-gray-900 truncate">
                                                        {product.name}
                                                      </h3>
                                                      {product.discount > 0 && (
                                                        <span className="flex-shrink-0 inline-block px-2 text-base font-medium text-red-500 line-through bg-orange-200 rounded-full py-0.5">
                                                          $ {roundWithTwoDecimals(product.price)}
                                                        </span>
                                                      )}
                                                      {product.priceWithDiscount > 0 && (<span className="flex-shrink-0 inline-block px-2 text-base font-medium text-blue-900 bg-blue-200 rounded-full py-0.5">
                                                        ${' '}
                                                        {roundWithTwoDecimals(
                                                          product.priceWithDiscount,
                                                        )}
                                                      </span>)}
                                                    </div>
                                                    <div className="mt-1 text-sm text-gray-500 truncate">
                                                      {product.description}
                                                      <br />
                                                      {((product?.pvp || 0) > 0) && (<div style={{ border: "2px solid red", width: "fit-content", borderRadius: "10px", padding: "2px" }}>Precio de Venta: <span style={{ fontWeight: "bold", fontSize: "1rem" }}>${roundWithTwoDecimals(product?.pvp || 0)}</span></div>)}
                                                    </div>

                                                    <div className="mt-1 text-sm text-blue-500 truncate">
                                                      {product.isImmediateResponse ? "Entrega Inmediata" : "Entrega no Inmediata"}
                                                    </div>
                                                    {(user?.me.customer?.balance || 0) <
                                                      finalPrice && (
                                                        <div className="mt-2 text-orange-400">
                                                          <FontAwesomeIcon
                                                            aria-hidden="true"
                                                            icon={faExclamationTriangle}
                                                          />
                                                          <span className="ml-3 text-xs">
                                                            No tienes saldo suficiente para comprar
                                                            este producto
                                                          </span>
                                                        </div>
                                                      )}
                                                  </div>
                                                </div>
                                              </Card>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    )}
                                  </div>
                                </div>
                              )}
                            </Panel>
                          ))}
                        </Collapse>




                      )}
                  </>
                ) : (
                  !loading && (
                    <div>
                      <span>No hay productos disponibles en este momento</span>
                    </div>
                  )
                )}
              </div>
            )




        }
      </div >
    </div >
  );
};
